document.addEventListener('DOMContentLoaded', function (event) {
  	document.querySelectorAll('.image--logo .cmp-image__image').forEach(
		function(selectEl) {
			var alt = selectEl.getAttribute('alt');

			var altOverlay = document.createElement('div');
			altOverlay.className = 'cmp-image__alt-text';
			altOverlay.innerHTML = '<p class="alt">' + alt + '</p>';
			selectEl.parentElement.append(altOverlay);
		}
	);
})
  