function runOnStart() {
	const mainHeader = document.querySelector('#main-header');
	mainHeader.classList.add('menu-hidden');

	const toggle = document.querySelector('#menu-toggle');
	toggle.setAttribute('aria-expanded', 'false');

	toggle.addEventListener('click', function() {
		const aria = toggle.getAttribute('aria-expanded');

		if (aria == "false") {
			mainHeader.classList.remove('menu-hidden');
			document.body.classList.add('js-menu-open');

			toggle.setAttribute('aria-expanded', 'true');
			document.addEventListener('keyup',escKeyHandler,false);
		}
		else {
			mainHeader.classList.add('menu-hidden');

			if (document.body.classList.contains('js-menu-open')) {
				document.body.classList.remove('js-menu-open');
				toggle.setAttribute('aria-expanded', 'false');
			}

			document.removeEventListener('keyup',escKeyHandler,false);
		}		
	});


	function escKeyHandler(event){
		var keycode=event.keyCode;
		switch (keycode) {
			case 27:
				if (window.innerWidth < 1190) {
					mainHeader.classList.add('menu-hidden');
					
					if (document.body.classList.contains('js-menu-open')) {
						document.body.classList.remove('js-menu-open');
						toggle.setAttribute('aria-expanded', 'false');
					}
					
					toggle.focus();
					document.removeEventListener('keyup',escKeyHandler,false);
					break;
				}
		}
	}
}
  

if(document.readyState !== 'loading') {
    runOnStart();
}
else {
    document.addEventListener('DOMContentLoaded', function () {
        runOnStart();
    });
}