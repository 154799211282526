(function () {
    'use strict';

    function onDocumentReady() {
        document.querySelectorAll('.contentfragment--date-month .cmp-contentfragment__element-value').forEach(
            function (selectEl) {
                var date = new Date(selectEl.innerHTML);

                if (date != "Invalid Date") {
                    var monthName = date.toLocaleString(document.documentElement.lang, {month: 'short'});

                    var year = date.getFullYear();

                    selectEl.innerHTML = monthName + " " + year;
                }
            }
        );

        document.querySelectorAll('.contentfragment--date-full .cmp-contentfragment__element-value').forEach(
            function (selectEl) {
                var date = new Date(selectEl.innerHTML);

                if (date != "Invalid Date") {
                    var day = date.getDate();

                    var monthName = date.toLocaleString(document.documentElement.lang, {month: 'short'});


                    var year = date.getFullYear();

                    selectEl.innerHTML = day + " " + monthName + " " + year;
                }
            }
        );
    }

    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();