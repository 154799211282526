document.addEventListener('DOMContentLoaded', function (event) {
	var trimWhitespace = function() {
		document.querySelectorAll('.cmp-button').forEach(
			function(buttonEl) {
				if (buttonEl && buttonEl.children.length == 0) {
					buttonEl.innerHTML = "";
				}
			}
		);
	};
	
	trimWhitespace();
})