(function () {
    'use strict';

    function onDocumentReady() {
        document.querySelectorAll('.dropdown .navigation_trigger').forEach(
            function (navigationTrigger) {
                navigationTrigger.addEventListener('click', () => {
                    var aria = navigationTrigger.getAttribute('aria-expanded');

                    if (aria === "true") {
                        var section = navigationTrigger.nextElementSibling;
                        section.setAttribute("data-closing", "true");
                        section.addEventListener('animationend', () => {
                                section.removeAttribute("data-closing");
                                navigationTrigger.setAttribute('aria-expanded', 'false');
                                document.removeEventListener('keyup', escKeyHandler, false);
                            },
                            {once: true}
                        );
                    } else {
                        if (window.innerWidth > 1190) {
                            closeOpenPanes();
                        }
                        navigationTrigger.setAttribute('aria-expanded', 'true');
                        document.addEventListener('keyup', escKeyHandler, false);
                    }
                })
            }
        );
    }


    function closeOpenPanes() {
        document.querySelectorAll('.dropdown .navigation_trigger[aria-expanded="true"]')
            .forEach(function (openPane) {
                var section = openPane.nextElementSibling;
                section.setAttribute("data-closing", "true");
                section.addEventListener('animationend', () => {
                        section.removeAttribute("data-closing");
                        openPane.setAttribute('aria-expanded', 'false');
                    },
                    {once: true}
                );
            })
    }

    function escKeyHandler(event) {
        var keycode = event.keyCode;
        if (keycode === 27 && window.innerWidth > 1190) {
            const activeNavTrigger = document.querySelector('.dropdown .navigation_trigger[aria-expanded="true"]');
            closeOpenPanes();
            activeNavTrigger.focus();
            document.removeEventListener('keyup', escKeyHandler, false);
        }
    }


    if (document.readyState !== "loading") {
        onDocumentReady();
    } else {
        document.addEventListener("DOMContentLoaded", onDocumentReady);
    }
})();